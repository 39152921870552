const da = {
  error: {
    unknownError: 'Der opstod en fejl. Prøv igen senere.',
  },
  heading: {
    entrances: 'Area entrances',
    tasks: 'Notifications',
    dashboard: 'Dashboard',
    info: 'Information and support',
    serviceChange: 'Service changes'
  },
  resources: {
    booking: 'Bestilling',
    orderManagement: 'Order Management',
    airAndSea: 'Air and Sea',
    calculator: 'Kalkulator',
    tracking: 'Sporing',
    reports: 'Rapporter',
    invoices: 'Fakturaer',
    docSearch: 'Dokumentsøgning',
    supportCases: 'Kundeservice',
    useradminExternal: 'Administration',
    customerSearch: 'Customer Search',
    warehousing: 'Warehousing',
    incoming: 'Indgående sendinger',
    checkout: 'Checkout',
    marketing: 'Markedsføring',
    noAccess: 'Du har ikke adgang til',
    getAccess: 'Du kan søge om adgang og rettigheder via <0>din profil</0>.'
  },
  entrances: {
    noShow: 'Der vises ingen indgange, tilføj en ved at klikke',
    showTile: 'Vis indgang',
    move: 'Flyt',
    left: 'til venstre',
    right: 'til højre',
    hide: 'Skjul',
    editEntrances: 'Rediger innganger',
    closeConfiguration: 'Luk redigering',
    configInfo: 'You can change the order by using drag-n-drop or the buttons on each entrance.',
    hiddenTilesHeading: 'Skjulte indgange',
    hiddenTilesInfo: 'If you choose to hide some entrances, they will be listed here. You can always show them again.',
    disabledAccessHeading: 'Ingen adgang'
  },
  customerService: {
    label: 'Kontakt kundeservice',
    openingHours: 'Mandag–fredag, ',
    keypad: 'Mybring: tryk ',
    trackingKeypad: 'Sporing: tryk ',
    then: ' derefter ',
    email: 'E-mail',
    telefon: 'Telefon',
    openCase: 'Opret ny sag i',
    guides: 'Mybring og API-hjælp',
    mybringInfo: 'Få hjælp til Mybring på vores',
    mybringInfoLinkText: 'rådgivningssider',
    mybringInfoLink: 'https://www.bring.dk/radgivning/mybring',
    apiInfo: "Læs om vores API'er og hvordan du kan integrere mod dem",
    apiInfoLink: 'Bring API-dokumentationen',
    apiUpdatesHeading: 'Abonner på API-opdateringer',
    apiUpdatesText: 'Abonner på <0>API-opdateringer</0> fra Bring.',
    apiUpdatesButton: 'Registrer e-mail',
    feedback: 'Tilbagemelding',
    feedbackHead: 'Hjælp os med at forbedre Mybring',
    feedbackInfo: 'Har du input til, hvordan Mybring kan forbedres? Giv tilbagemelding direkte til udviklingsteamene.',
    feedbackForm: 'Åbne formular',
  },
  revisedServices: {
    description: 'Nogle af dine kundenumre har fået tjenester med nye navn og prismodeller.',
    link: 'Information om ændringerne',
  },
  oebsW3: {
    apiLabel: 'Ændringer på API',
    webLabel: 'Ændringer på web',
    intro: 'Fra den 2. maj 2023 skifter vi til et nyt og forbedret faktureringssystem for vores Home Delivery-tjenester i Norge og Danmark samt regionale budtjenester i Finland. Herunder er en oversigt over de ændringer, du vil se på forskellige steder i Mybring og våre API-er.',
    api: {
      title: 'Ændringer i Mybrings API’er som følge af det nye faktureringssystem',
      intro:
        'Fra den 2. maj 2023 skifter vi til et nyt og forbedret faktureringssystem for vores Home Delivery-tjenester i Norge og Danmark samt regionale budtjenester i Finland. Nedenfor er en oversigt over ændringerne, der gælder for dig som API-kunde.',
      customernumbers: 'Dine API-kundenumre.',
      sg: {
        label: 'Shipping Guide API',
        info1:
          'Forespørgsler på udgående Home Delivery-tjenester til/fra Norge og Danmark, Home Delivery-returtjenester til/fra Norge og Danmark samt regionale budtjenester i Finland skal ske med hovedkundenummer. Du skal selv ændre dit nye API-hovedkundenummer for sådanne forespørgsler. Dit API-hovedkundenummer er identisk med din virksomheds hovedkundenummer. ',
        info2: 'Dit gamle API-kundenummer vil stadig fungere i en overgangsperiode. Vi anbefaler dog, at du ',
        link: 'ændrer det API-kundenummer, som benyttes til forespørgsler på de nævnte tjenester',
        text: ' så hurtigt som muligt for at undgå problemer senere.',
      },
      booking: {
        label: 'Booking API',
        info1:
          'Bestilling af Home Delivery-tjenester til/fra Norge og Danmark, der udføres via Booking API, skal ske med hovedkundenummer. Dette gælder både udgående Home Delivery-tjenester til/fra Norge og Danmark og Home Delivery-returtjenester til/fra Norge og Danmark.',
        info2:
          'Du skal selv ændre dit nye API-hovedkundenummer for sådanne bestillinger. Dit API-hovedkundenummer er identisk med din virksomheds hovedkundenummer. ',
        info3: 'Dit gamle API-kundenummer fungerer fortsat i en overgangsperiode. Vi anbefaler imidlertid, at du ',
        link: 'ændrer det API-kundenummer, som benyttes til bestilling af de nævnte tjenester',
        text: ' så hurtigt som muligt for at undgå problemer senere.',
      },
      invoices: {
        label: 'Invoice API',
        info1:
          'Fakturaer skal tages ud med hovedkundenummer. Dit API-hovedkundenummer er identisk med din virksomheds hovedkundenummer. ',
        info2: 'Dit gamle API-kundenummer fungerer fortsat i en overgangsperiode. Vi anbefaler imidlertid, at du ',
        link: 'ændrer det API-kundenummer, som benyttes til at hente fakturaer',
        text: ' så hurtigt som muligt for at undgå problemer senere.',
      },
      demosg: {
        label: 'Demo Shipping Guide',
        info: 'Efter skift til nyt faktureringssystem vil du finde Home Delivery-tjenester under fanen "Revised services".',
      },
      web: {
        link: 'Oversigt over ændringerne i Mybrings webportal',
      },
    },
    web: {
      title: 'Ændringer i Mybring som følge af nyt faktureringssystem',
      intro:
        'Fra den 2. maj 2023 skifter vi til et nyt og forbedret faktureringssystem for vores Home Delivery-tjenester i Norge og Danmark samt regionale budtjenester i Finland. Herunder er en oversigt over de ændringer, du vil se på forskellige steder i Mybring.',
      booking: {
        label: 'Bestilling',
        new: 'Ny bestilling',
        info: 'Bestilling af Home Delivery-tjenester i/til Norge og Danmark skal ske med hovedkundenummer. Dette gælder både udgående Home Delivery-tjenester i/til Norge og Danmark under "Sende til én" og Home Delivery-returtjenester i/fra Norge og Danmark under "Retur fra én". Kundevælgeren ændres automatisk til at vise hovedkundenummeret. For bestilling af regionale budtjenester i Finland vil kundevælgeren være uændret.',
      },
      pickup: {
        new: 'Ny afhentning',
        info: 'Bestilling af afhentning af Home Delivery-sendinger i Norge skal ske med hovedkundenummer. Kundevælgeren ændres automatisk til at vise hovedkundenummeret.',
      },
      orderhistory: {
        label: 'Bestillingshistorik',
        info: 'Alle bestillinger af Home Delivery-tjenester uanset land, der laves efter skiftet til nyt faktureringssystem, vil blive vist med hovedkundenummer. Det samme gælder bestilte afhentninger af Home Delivery-sendinger i Norge. Bestillinger af regionale budtjenester i Finland vil blive vist med samme kundenummer som tidligere.',
      },
      tracking: {
        label: 'Sporing',
        info: 'Her er der ingen ændringer. Både det gamle kundenummer og hovedkundenummeret vises som før.',
      },
      invoices: {
        label: 'Fakturaer',
        info1:
          'Faktura på alle bestillinger af Home Delivery-tjenester samt regionale budtjenester i Finland efter skiftet til nyt faktureringssystem vil komme under hovedkundenummer.',
        info2:
          'På fakturaer under hovedkundenummer vil du få mere information om din faktura end det du får i dag, såsom:',
        info3: {
          type: 'Fakturatype',
          source: 'Fakturakilde',
          amount: 'Fakturabeløb',
          currency: 'Valuta',
          text1: 'Hvilken oprindelig faktura en kreditnota er tilknyttet',
          text2: 'Om fakturaen er betalt eller ikke',
        },
        info4:
          'Du vil se nogle kolonner i kolonnevælgeren for Specificeret fakturarapport (dvs. fakturaspecifikationen), som ikke vil have indhold. Årsagen til denne udvidelse er, at Specificeret fakturarapport vil blive en fælles rapport for alle kunder, og nogle kolonner er kun gyldige for visse serviceområder.',
        info5:
          'Det er muligt at oprette abonnement på Specificeret fakturarapport. Abonnementet oprettes på hovedkundenummeret.',
      },
      customerService: {
        label: 'Kundeservice',
        info: 'Her er der ingen ændringer. Du indberetter sager på hovedkundenummer som før.',
      },
      api: {
        label: 'API-ændringer',
        info: 'Du skal skifte til dit nye API-hovedkundenummer ved brug af enkelte Mybring API’er.',
        link: 'Læs mere',
      },
    },
  },
  missingPhone: {
    heading: 'Manglende telefonnummer',
    text: 'Vi har ikke registreret et telefonnummer på din konto. Dette er nødvendigt for at få fuld udbytte af MyBring-tjenesterne (f.eks. modtage SMS-er). For at opdatere dit telefonnummer beder vi dig gå til ',
    link: 'siden for opdatering af din profil.',
  },
  dashboard: {
    select: 'Vælg kundenummer',
    title: 'Planlagte leveringer',
    yesterday: 'I går',
    today: 'I dag',
    tomorrow: 'I morgen',
    moredays: 'Se flere dage',
    error: 'Vi har problemer med at vise alle detaljer. Prøv igen senere.',
    loading: 'Opdaterer',
    remaining: 'I transit',
    loaded: 'Lastet på bil',
    attempted: 'Forsøgt leveret',
    pickup: 'På udleveringssted',
    delivered: 'Leveret',
  },
  overview: {
    titleVol: 'Faktureret volumen',
    titleCost: ' og omkostninger',
    loading: 'Opdaterer',
    error: 'Vi har problemer med at vise alle detaljer. Prøv igen senere.',
    menu: {
      thisyear: 'År til dato',
      thismonth: 'Denne måned til dato',
      last12month: 'Seneste 12 måneder',
      last24month: 'Seneste 24 måneder',
    },
    category: 'Forsendelser',
    info: 'Ingen data tilgængelig for denne kunde',
  },
  welcome: {
    skip: 'Skip til indhold',
    login: 'Log ind',
    forexisting: 'For eksisterende brugere',
    register1: 'Registrer ny virksomhed',
    register2: 'Få et kundenummer og en bruger til at logge ind',
    register3: 'Opret flere brugere',
    register4: 'Hvis virksomheden allerede har et kundenummer',
    features: {
      heading: 'Få et overblik over logistikken med Mybring',
      booking: 'Bestilling',
      bookingIntro: 'Bestil tjenester, der indgår i din aftale. Send til én eller flere modtagere samtidig.',
      tracking: 'Sporing',
      trackingIntro: 'Giver afsender udvidede oplysninger samt adgang til signatur- og ændringstjenester.',
      reports: 'Rapporter',
      reportsIntro: 'Få overblik over status, leveringskvalitet, afvigelser, omkostninger og klimaaftryk.',
      invoices: 'Fakturaer',
      invoicesIntro: 'Få overblik over dine fakturaer med betalingsfrist og -status.',
      supportcases: 'Kundeservice',
      supportcasesIntro:
        'Se alle registrerede sager samt sagslog og seneste status, og opret nye sager direkte i Mybring.',
      warehousing: 'Warehousing',
      warehousingIntro:
        'Få fuld kontrol over din beholdning. Få et overblik over dine salgs- og indkøbsordrer og se den seneste status.',
      useradmin: 'Administration',
      useradminIntro: 'Som administrator har du fuld kontrol over virksomhedens kundenummer og Mybring-brugere.',
      link: 'Læs mere om Mybring',
    },
    customerservice: {
      label: 'Kundeservice',
      no: 'Bring Norge',
      se: 'Bring Sverige',
      dk: 'Bring Danmark',
      fi: 'Bring Finland',
    },
    terms: 'Vilkår',
    download: 'Download',
    policy: 'Privacy policy',
    cookies: 'Cookies',
  },
  agreementConversion: {
    read: 'Read more.',
    here: 'here',
    notAuthorized:
      'The logged-in user is not authorized to access this page. If you have access to the captioned service, but unable to access this page, please contact customer service.',
    infoPage: {
      header: 'Changes in Mybring related to new service names',
      description: {
        para1:
          'Bring is revising the service portfolio. Some of our services have been given new service names, service codes and pricing models. ',
        para2:
          'From a given date, your company will be converted to services with new names (revised services). When at least one customer number attached to your user has been converted, you will book revised services. This is done by using your company’s main customer number. Below is an overview of the various changes you will see in Mybring.',
        para3: 'To read more about the ',
        link: 'revised services',
        text: ' go directly here.',
      },
      api: {
        header: 'API changes',
        text: 'You use the main customer number for revised services. ',
        introduction: 'Changes in API related to conversion',
        bullets: {
          1: 'To request revised services via our APIs you use your new API customer number, which is identical to your company’s main customer number. To read more about revised services in our APIs, click ',
          2: 'To see your API customer numbers, click ',
          3: 'For a limited period, any bookings of old services made with old API customer numbers will automatically be mapped to the ',
          link: 'corresponding ',
          text: 'revised service. ',
          4: 'If you request old services with your new API-customer number, or revised services with your old API customer number, the request will fail. ',
        },
      },
      booking: {
        header: 'Booking',
        order: 'New Order',
        text: 'You start booking revised services when one customer number attached to your user has been converted. ',
        heading: {
          text1:
            'To check which services you can book with old customer numbers and which services you can book with main customer number, click ',
          text2:
            'For services with new service names the API-request is unchanged, except for the way to request value added services. For old services both old and new way of requesting can be used. However, it is recommended to use the new way for all services as the old way of requesting value added services will be phased out. ',
        },
        table: {
          row: {
            BPAKKE: 'Business Parcel',
            EKSPRESS09: 'Business Parcel Express Overnight',
            CARGO: 'Groupage and Partload*',
            SERVICEPAKKE: 'Climate-neutral Service Parcel',
            PA_DOREN: 'Home Delivery Parcel',
            BPAKKE_RETURSERVICE: 'Business Package Return Service',
            EKSPRESS09_RETURSERVICE: 'Business Parcel Express Overnight Return Service',
            SERVICEPAKKE_RETURSERVICE: 'Service Parcel Return Service',
            5000: 'Business Parcel',
            4850: 'Express next day',
            5100: 'Business groupage',
            5300: 'Business partload',
            5800: 'Pick-up parcel',
            5600: 'Home delivery parcel',
            9000: 'Return business parcel',
            9600: 'Return express',
            9100: 'Return business groupage',
            9300: 'Return from pick-up point',
          },
        },
        text1:
          'After being converted, you will be redirected back to the booking home page if you try to access old services by using bookmarks etc.',
        text2:
          "For revised services, eAdvising is no longer a separate value added service: it's now included in the service and it's base price. Letter notification can be ordered at an additional cost (possible when booking single outbound shipments).",
        flow3: 'Mailbox Packet',
        oldImage: 'Example - Mailbox Packet customer selector before any conversion:',
        newImage:
          'Example - Mailbox Packet customer selector after conversion. Bring Company1 AS and Bring Company3 are converted/main customer numbers. The others have not been converted yet:',
      },
      pickup: {
        header: 'New Pickup',
        text: 'For pickups in Norway, the customer selector will change when you have at least one converted customer number attached to your user. ',
        text1:
          'The customer selector for pickups in Norway will change when you have at least one converted customer number attached to your user. From that point of time, also the customer selector will show at least that one main customer number for invoicing. For parcel pickups in Sweden and Denmark, there will be no changes to the customer selector - i.e. it will continue to show only old customer numbers.',
        text2:
          "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector.",
        oldImage: 'Example – Pickup customer selector before any conversion:',
        newImage:
          'Example – Pickup customer selector after conversion. Bring Company1 AS and Bring Company3 AS are converted/main customer numbers. The others have not been converted yet:',
      },
      orderHistory: {
        header: 'Order history',
        text: 'All bookings are reflected in order history. For old service bookings, you will see the old customer number being displayed. For revised service bookings, you will see the main customer number being displayed.',
      },
      addressbook: {
        header: 'Address book',
        text: 'When a customer number attached to your user has been converted, all contacts saved on the old customer number will be migrated to the main customer number. ',
        list: {
          text1:
            'When a customer number attached to your user has been converted, all contacts saved on the old customer number will be migrated to the main customer number. Similarly, all contacts saved on old customer numbers that are not migrated yet will remain on the old customer number. ',
          text2:
            "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector.",
          text3: 'New contacts can be added to both old and main customer numbers.',
        },
        image: {
          oldText: 'Example - Address book customer selector before any conversion: ',
          newText:
            'Example - Address book customer selector after conversion. Bring Company1 AS (1000-12345678) and Bring Company1 AS (00011111111) are now converted and merged under the main customer number Bring Company1 AS (11111111). The others have not been converted yet: ',
        },
      },
      shoppingCart: {
        header: 'Shopping cart',
        text: 'Any items in your shopping cart involving old services will automatically be deleted when one of the customer numbers attached to your user has been converted. ',
        oldImage: 'Example - Shopping cart before any conversion (including an old service):',
        newImage: 'Example - Shopping cart after conversion (the old service has been deleted):',
        text1:
          'When at least one customer number attached to your user has been converted, you can only book revised services. If you had any items in your shopping cart involving old services, they have been deleted after the conversion. Your shopping cart will display a message informing about this.',
        text2:
          'This change only applies to the group of services that are impacted by the conversion. If you had any items in your shopping cart involving other services, they still remain in the shopping cart after the conversion.',
      },
      favourites: {
        header: 'Favourites',
        text: 'Any items among your favourites involving old services will automatically be disabled for booking when one of the customer numbers attached to your user has been converted. ',
        oldImage: 'Example - Favourites before any conversion (including an old sevice):',
        newImage: 'Example - Favourites after conversion (the old service has been disabled for booking):',
        text1:
          'When at least one customer number attached to your user has been converted, you can only book revised services. If you have any favourites involving old services, they have been disabled for booking after the conversion. Your favourites page will display a message informing about this.',
        text2:
          'This change only applies to the group of services that are impacted by the conversion. If you have any items among your favourites involving services that are not impacted, they still remain bookable from the favourites page after the conversion.',
        text3:
          'To save the corresponding revised service as a favourite, click "Save as favourite" on the confirmation page next time you book this service.',
      },
      returnAdmin: {
        header: 'Return admin',
        text: 'When a customer number has been converted, any return agreements for old services will automatically be migrated to return agreements for the corresponding revised services. The return agreement for the revised service will be using the main customer number. If you create a new return agreement for a revised service on a converted customer number, you must use the main customer number when searching. For new return agreements on customer numbers that are not converted, the old customer number is used. The same goes for new return agreements for services outside scope of conversion - even though the customer is converted.',
        text1: 'Old return services impacted by the conversion and the new service name/code they are mapped to:',
        table: {
          heading1: 'Old service name',
          heading2: 'Old service code',
          heading3: 'New service name',
          heading4: 'New service code',
          row1: {
            oldService: {
              text: 'Business Package Return Service',
              code: 'BPAKKE_DOR-DOR_RETURSERVICE (1225)',
            },
            newService: {
              text: 'Return business parcel',
              code: '9000',
            },
          },
          row2: {
            oldService: {
              text: 'Business Parcel Express Overnight Return Service',
              code: 'EKSPRESS09_RETURSERVICE (1224)',
            },
            newService: {
              text: 'Return express',
              code: '9600',
            },
          },
          row3: {
            oldService: {
              text: 'Service Parcel Return Service',
              code: 'SERVICEPAKKE_RETURSERVICE (1207)',
            },
            newService: {
              text: 'Return from pick-up point',
              code: '9300',
            },
          },
        },
        text2: '',
      },
      adminBulksplit: {
        header: 'Bulk admin',
        text: 'Bulk agreements only apply to services that are not covered by the conversion, and there are no changes to existing bulk agreements.',
        text1:
          'Bulk agreements only apply to services that are not covered by the conversion, and there are no changes to existing bulk agreements. It’s possible to search for customers in bulk admin by using old customer numbers or main customer numbers, depending on whether the customer is converted yet or not. When searching for main customer numbers, relevant old customer numbers will show, and the user must choose which old customer number to create the bulk agreement on.',
        imageText: 'Example - Search in bulk admin for converted customer: ',
      },
      calculator: {
        header: 'Calculator',
        text: 'You can calculate prices and leadtimes for revised services when one customer number attached to your user has been converted. ',
        text1:
          'The customer selector in calculator will change when you have at least one converted customer number attached to your user. From that point of time, you can use the main customer number to request for prices and leadtimes for the revised services Business groupage and Business partload.',
        text2:
          "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector. In that case, you can still calculate prices and leadtimes for the old service Groupage and Partload by using one of the old customer numbers in the dropdown.",
        text3:
          'Since the old service Groupage and Partload is split into two revised services, Business groupage and Business partload, you must now select which of the revised services you are requesting for when using the calculator with your main customer number.',
        oldImage: 'Example - Calculator before any conversion (old customer number/old service):',
        newImage: 'Example - Calculator after conversion (main customer number/revised services):',
      },
      tracking: {
        header: 'Tracking',
        text: 'There will be no changes in tracking. We display both the old and the main customer number, as before.',
      },
      reports: {
        header: 'Reports',
        text: 'In the customer selector we hide the old customer numbers after conversion, except from Bring Parcels AB. ',
        subheading: 'Reports & Subscriptions',
        text1:
          'Reports are requested using main customer numbers, and will include all old customer numbers for the selected service type. If you previously had 3 customer numbers for groupage that are all combined within the same main customer number, all of these will be included in the report.',
        text2: 'The columns in the reports are kept, and both old and new services are included in the same report.',
        heading: 'Reports Subscriptions',
        text3:
          'We move subscriptions from old customer numbers to main customer numbers, with the same setup as before. The subscription owner will get an email when that happens. If you used to have subscriptions for multiple customer numbers that are combined within the same main customer number, you might end up with duplicate subscriptions. In that case, you can delete these in Reports-Subscriptions.',
        oldImage: 'Example of old and new customer selector',
        newImage: 'Subscription page where you can edit your subscriptions',
      },
      dashboard: {
        header: 'Dashboard',
        text: 'The old customer numbers will not be available, but the service types will be. If you previously had 3 customer numbers for groupage that now are combined within the same main customer number, these will be combined in the reporting.',
      },
      invoices: {
        header: 'Invoices',
        text: 'When your customers are converted, the old customer numbers are removed from the customer selector. Invoices for old customer numbers are available when selecting main customer number. Invoices will still be available in one year.',
      },
      customerservice: {
        header: 'Customer Service',
        text: 'No changes. You still create cases using main customer numbers, as before.',
      },
      orderManagement: {
        header: 'Order Management',
        text: 'For all APIs, we will use new customer number instead of old customer number. Also, all suppliers will be ported to new customer number. For more details',
        text1:
          "Users having customers for which agreement has been migrated to new customer number, will use new customer number for all API's and UI.",
        heading1: {
          label: 'API Changes',
          subheading1: {
            label: 'IsCustomerValidForOrderManagement',
            text: 'For this api new customer number will be used, because for a customer for which agreement has been migrated to new customer all pickup order will be cretaed on new customer number.',
          },
          subheading2: {
            label: 'ListOrderInformation',
            text: 'As order will be created using new customer number, so to list order info new customer number will be used.',
          },
          subheading3: {
            label: 'ListOrderItems',
            text: 'As order will be created using new customer number, so to list order items new customer number will be used.',
          },
          subheading4: {
            label: 'SenderStatusForOrder',
            text: 'User will have to use new customer number to find sender status.',
          },
          subheading5: {
            label: 'ListPackagingListForOrder',
            text: 'Users will have to use new customer number to get packaging list of order as order for agreement converted customers will be created using new customer number.',
          },
          subheading6: {
            label: 'Report API',
            text: 'For all the order for which booking has been done using new customer number, will have to use new customer number to generate all types of reports.',
          },
        },
        heading2: {
          label: 'Mybring UI Changes',
          text1:
            'Users will have to search purchase order using new customer number for converted customer (dropdown will show new customer number for customer having agreement with new customer number, and for customers with old agreement, old customer number will be displayed.)',
          text2:
            'User will have to create supplier using new customer number for any customer that is new to Purchase order',
          text3:
            'User having suppliers on old customer number will automatically changed to supplier with new customer number',
          text4:
            'All suppliers that has to be used in purchase order by a user, should be added to that user using super user profile.',
        },
      },
      shippingguide: {
        header: 'Shipping guide',
        text1: 'You need to upgrade to ',
        link: 'Shipping Guide 2.0 ',
        text2:
          'to be able to request services with new service names after conversion. Shipping Guide 2.0 supports both old services and services with new names, so it is recommended that you upgrade as quickly as possible to avoid that your integration stops working.',
      },
      newPickup: {
        header: 'New pickup',
        text: 'After your company’s conversion date, you start requesting pickups in Norway with your new API customer number. Your old API customer number will still work for a transition period. However, it is recommended to use your new API customer number as soon as you are converted. For pickups outside Norway there are no changes, and you continue to use your old API customer number.',
      },
      customerServiceMapping: {
        header: 'Mapping between customer number and available services',
        text: 'Which customer numbers attached to your user that can be used to book which services.',
        superuser:
          "You are logged in as a superuser. As a superuser you don't have any customer numbers attached to your user, hence customer/service mapping in not available for you.",
        customer: {
          name: 'Name',
          customerNo: 'Customer Number',
          code: 'Country Code',
          products: 'Products',
        },
      },
      promotions: {
        text1:
          'When you do a coverage search NO -> NO with at least one converted customer number attached to your user, you will see revised services instead of old services. When booking revised services, you will be using your main customer number - i.e the customer selector will show your main customer number(s) for invoicing. The aforementioned changes apply to single outbound/return as well as multiple outbound/return.',
        text2:
          'There are no changes to services that apply to other country combinations, and you will continue using your old customer number for these bookings. To check which services you can book with which customer number, click ',
        text3: 'Old services impacted by the conversion, and which new service name/code they are mapped to: ',
        table: {
          heading1: 'Old service name',
          heading2: 'Old service code',
          heading3: 'New service name',
          heading4: 'New service code',
        },
      },
      cargo:
        '* There is a one-to-one mapping between the old and revised services (and thereby their respective booking flows) - except for the old service Groupage and Partload. This service had one common booking flow, but is split into two revised services/booking flows: Business Groupage and Business Partload. A separate booking flow has also been created for the revised service Return business groupage (New order -> Single return). The old corresponding service was booked via the booking flow for Groupage and Partload by selecting "Return" on the shipping details page. ',
      coverageSearch: {
        oldImage: 'Outgoing coverage search NO -> NO before conversion (old services): ',
        newImage: 'Outgoing coverage search NO -> NO after conversion (revised services): ',
      },
      orderToMailbox: {
        text1:
          'The customer selector for Mailbox Packet will change when you have at least one converted customer number attached to your user. From that point of time, the customer selector will show at least that one main customer number for invoicing. ',
        text2:
          "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector.",
      },
    },
  },
  operationalMessages: {
    backtofrontpage: 'Tilbage til forsiden',
    select: 'Vælg land',
    title: 'Driftsmeddelelser',
    seemore: 'Flere driftsmeddelelser',
    loading: 'Opdaterer',
    country: {
      no: 'Norge',
      se: 'Sverige',
      dk: 'Danmark',
    },
    error: 'Vi har problemer med at vise driftsmeddelelser. Prøv igen senere.',
    none: 'Ingen meddelelser at vise.',
  },
  notcollectedatpup: {
    label: 'Ikke afhentet ved udleveringssted',
    onetotwodays: '1-2 dage tilbage',
    threetofourdays: '3-4 dage tilbage',
    error: 'Vi har problemer med at vise alle detaljer. Prøv igen senere.',
  },
  taskCenter: {
    messages: 'Messages',
    useradmin:'Administration',
    cases: {
      caseNumber: 'Sag: ',
      status: {
        NEW: 'Ny',
        IN_PROGRESS: 'Under behandlings',
        AWAITING_CUSTOMER: 'Venter på kunde',
        CLOSED: 'Afsluttet',
      },
    },
  },
  searchable_select: {
    customerEmptyText: 'Ingen kunder matcher din søgning...',
    customerPlaceholderText: 'Søg og vælg en af dine kunder...',
    listboxLabelCustomer: 'Liste med valgbare kunder',
  },
  bankInfo: {
    header: 'Bankskifte | Det skal du gøre',
    p1: 'Posten Bring AS og dets datterselskaber har for nylig skiftet bank. De nye betalingsoplysninger findes på fakturaen. For at sikre betaling af fakturer og undgå forsinkelser, skal de nye betalingsoplysninger opdateres i dine økonomisystemer.',
    p2: 'Læs mere her: ',
    ctaLabel: 'Skift af koncernbank for Posten Bring AS',
    ctaLink: 'https://www.bring.dk/kundeservice/kundeservice-erhverv/skifte-koncernbank'
  },
  checkITContactInfo: {
    header: 'Gratis checkout rådgivning til netbutikker',
    text: 'Vil du have hjælp til at optimere din checkout? Vores checkout-eksperter står klar til at hjælpe dig! Kontakt os på <1>checkoutdk@bring.com</1>',
    emailLink: 'mailto:checkoutdk@bring.com',
  },
};

export default da;
