export default {
  defaultLocale: 'no',
  userType: '',
  fullName: '',
  email: '',
  urls: {
    resources: '/frontpage/resources',
    revisedServices: '/frontpage/revisedServicesInfo',
    supportCases: '/support/cases/api/updatedCasesCount',
    reports: '/reports-dashboard/api/status/',
    overview: '/reports-dashboard/api/overview/',
    customers: '/booking-api/api/customers.json',
    frontpageCasesList: '/support/frontpage/api/cases/frontpageCases.json',
    frontpageNotification: '/messages/notifications/message-list',
    userAdminInternalRequests: '/useradmin/internal/request-list',
    userAdminExternalRequests: '/useradmin/api/external/request-list',
    operationalMessages: '/operationalMessages',
    operationalMessage: '/operationalMessage',
    entrance: {
      booking: '/order',
      calculator: '/order/calculator',
      tracking: '/track',
      reports: '/reports-ui/wizard',
      invoices: '/invoicearchive/',
      docsearch: '/document-search',
      supportCases: '/support/cases',
      useradminExternal: '/useradmin/external/administration',
      useradminInternal: '/useradmin/internal/customers',
      superuserSearch: '/useradmin/superuser/customersearch',
      ordermanagement: '/po',
      airAndSea: '/air-and-sea',
      warehousing: '/warehousing',
      incoming: '/recipient-portal',
      checkout: '/checkout',
      marketing: '/branding',
    },
    entranceApi: '/frontpage/api/user/entrances',
  },
};
